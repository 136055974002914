export default class StockDetailModel {
  constructor() {
    this.name_ko = ''; // 종목명(Ko)
    this.name_en = ''; // 종목명(En)
    this.code = ''; // 종목코드
    this.industry = ''; // 산업
    this.market_capitalization = ''; // 시가총액
    this.description_one_line = ''; // 한줄소개
    this.sales_composition = [this.sales_composition_item]; // 매출구성
    this.sales_composition_item = {
      percent: null,
      title: '',
      info_list: [''],
    };
    this.competitors_top3 = [ // 경쟁사 TOP3
      {
        connect_card: true,
        name_ko: '',
        code: '',
      },
      {
        connect_card: true,
        name_ko: '',
        code: '',
      },
      {
        connect_card: true,
        name_ko: '',
        code: '',
      },
    ];
    this.positive_prospect = { // 긍정적 전망
      title: '',
      desc: '',
    };
    this.negative_prospect = { // 부정적 전망
      title: '',
      desc: '',
    };
    this.focus_points = [this.focus_points_item]; // 주목해야 할 포인트
    this.focus_points_item = {
      title: '',
      desc: '',
    };
    this.special_jobs = [this.special_jobs_item]; // 스페셜 직업
    this.special_jobs_item = {
      name_ko: '',
      name_en: '',
      desc: '',
    };
    this.major_events = [this.major_events_item]; // 주요한 사건들
    this.major_events_item = {
      year: '',
      title: '',
      desc: '',
    };
    this.sec_link = ''; // seo 링크
    this.keyword_tag = [this.keyword_tag_item]; // 단어카드
    this.keyword_tag_item = {
      name_ko: '',
      name_en: '',
      desd: '',
    };

    // ETF
    this.etf_info = { // ETF 정보
      asset_management_insitution: '',
      inception_date: '',
      one_year_return: null,
      three_year_return: null,
    };
    this.etf_introduce = [this.etf_introduce_item]; // ETF 소개
    this.etf_introduce_item = {
      title: '',
      desc: '',
    }
    this.major_investment_firms = [this.major_investment_firms_item]; // 대표적인 투자 기업
    this.major_investment_firms_item = {
      connect_card: '',
      code: '',
      name: '',
    }
  }
  setData(obj) {
    const {
      name_ko,
      name_en,
      code,
      industry,
      market_capitalization,
      description_one_line,
      sales_composition,
      competitors_top3,
      positive_prospect,
      negative_prospect,
      focus_points,
      special_jobs,
      major_events,
      sec_link,
      keyword_tag,

      etf_info,
      etf_introduce,
      major_investment_firms,
    } = obj;
    this.name_ko = name_ko;
    this.name_en = name_en;
    this.code = code;
    this.industry = industry;
    this.market_capitalization = market_capitalization;
    this.description_one_line = description_one_line;
    this.sales_composition = sales_composition.map(item => {
      if (item.info_list.length === 0) {
        item.info_list = [''];
      }
      return item;
    });
    this.competitors_top3 = competitors_top3;
    this.positive_prospect = positive_prospect;
    this.negative_prospect = negative_prospect;
    this.focus_points = focus_points;
    this.special_jobs = special_jobs;
    this.major_events = major_events;
    this.sec_link = sec_link;
    this.keyword_tag = keyword_tag;
    this.etf_info = etf_info;
    this.etf_introduce = etf_introduce;
    this.major_investment_firms = major_investment_firms;
  }
  getData() {
    let obj = {
      name_ko: this.name_ko,
      name_en: this.name_en,
      code: this.code,
      industry: this.industry,
      market_capitalization: this.market_capitalization,
      description_one_line: this.description_one_line,
      sales_composition: this.sales_composition,
      competitors_top3: this.competitors_top3,
      positive_prospect: this.positive_prospect,
      negative_prospect: this.negative_prospect,
      focus_points: this.focus_points,
      special_jobs: this.special_jobs,
      major_events: this.major_events,
      sec_link: this.sec_link,
      keyword_tag: this.keyword_tag,
      etf_info: this.etf_info,
      etf_introduce: this.etf_introduce,
      major_investment_firms: this.major_investment_firms,
    };
    return obj;
  }
}
