import StockDetailModel from '@/views/stock/detail/model/StockDetailModel';

export default class MemberDetailViewModel {
  constructor() {
    this.model = new StockDetailModel();
    this.temp_data = {
      name_ko: '애플',
      name_en: 'Apple Inc. Common Stock',
      code: 'AAPL',
      industry: '정보기술',
      market_capitalization: '5.8조원',
      description_one_line:
        'Apple은 아이폰, 맥북 등 혁신적인 전자제품과 iOS 소프트웨어로 유명하며 스티브잡스가 차고에서 시작한 역사로도 알려진 세계적인 IT 기업입니다.',
      sales_composition: [
        {
          percent: 52,
          title: 'iPhone',
          info_list: [],
        },
        {
          percent: 22,
          title: '서비스',
          info_list: [
            '광고 수익 ',
            'Apple Care 보증 서비스',
            '클라우드 서비스 ',
            '디지털 콘텐츠 (App Store, Apple Arcade, Apple Music 등)',
            '결제 서비스 (Apple Card, Apple Pay) ',
          ],
        },
        {
          percent: 10,
          title: '웨어러블, 홈 & 액새서리 기기',
          info_list: [
            'Apple Watch인 스마트워치와 AirPods과 같은 무선 헤드폰으로 구성',
          ],
        },
        {
          percent: 8,
          title: 'Mac',
          info_list: [
            'Apple Watch 시리즈의 스마트워치',
            'AirPods 시리즈의 무선 헤드폰',
          ],
        },
        {
          percent: 7,
          title: 'iPad',
          info_list: [
            'Apple Watch 시리즈의 스마트워치',
            'AirPods 시리즈의 무선 헤드폰',
          ],
        },
      ],
      competitors_top3: [
        {
          connect_card: true,
          name_ko: '삼성전자',
          code: '005930',
        },
        {
          connect_card: true,
          name_ko: '알파벳',
          code: 'GOOGL',
        },
        {
          connect_card: true,
          name_ko: '마이크로소프트',
          code: 'MSFT',
        },
      ],
      positive_prospect: {
        title:
          '프리미엄 스마트폰 시장 성장, 인공지능 기술 진보, 신규 사업 진출 등으로 애플의 매출과 수익이 증가할 가능성이 높아요!',
        desc:
          '애플은 아이폰, 애플 워치 등 다양한 제품을 판매하며 꾸준히 성장하고 있어요. 프리미엄 스마트폰 시장의 성장세가 지속된다면 애플의 아이폰 판매량이 증가할 수 있으며, 인공지능 기술을 적극 활용하여 더욱 혁신적이고  제품을 개발할 수 있을 것입니다. 더불어, 애플이 AR/VR 기기, 자율주행차 등 신규 사업 분야에 진출함으로써 새로운 수익원을 만들어낼 수 있어요. ',
      },
      negative_prospect: {
        title:
          '경쟁 심화, 규제 강화, 부품 공급 문제 등 다양한 어려움으로 인해 애플의 수익성이 악화될 수도 있어요.',
        desc:
          '요즘에는 삼성전자, 샤오미 등 경쟁사들이 애플을 따라잡기 위해 노력하고 있어요. 또한 개인정보 보호, 세금 문제와 같은 정부의 테크 기업 규제 움직임이 활발해지고 있어요. 이에 더해 미국과 중국의 무역 분쟁 등으로 인해 부품 공급 문제도 발생한다면 수익성이 약화될 수 있어요. ',
      },
      focus_points: [
        {
          title: '프리미엄 스마트폰 시장 성장',
          desc:
            '프리미엄 스마트폰 시장 성장은 애플 아이폰 판매 증가로 이어질 것으로 예상됩니다.',
        },
        {
          title: '신규 제품/서비스 출시',
          desc:
            '애플은 현재 앱스토어와 아이폰 등의 제품 및 서비스를 제공하고 있지만, 고객의 니즈를 충족시킬 수 있는 AR/VR 기기와 같은 혁신적인 제품과 서비스를 출시한다면 기업의 수익성이 한층 더 향상될 것으로 예상됩니다.',
        },
        {
          title: '웨어러블 기기 시장 성장',
          desc:
            '애플 워치와 에어팟 등이 속해있는 웨어러블 기기 시장이 성장하고 있어요. 웨어러블 기기 시장 성장은 애플의 매출 및 수익 증가로 이어질 것으로 예상됩니다."',
        },
      ],
      special_jobs: [
        {
          name_ko: '특허 변호사',
          name_en: 'Patent Counsel',
          desc:
            '애플이 보유한 기술과 특허를 보호하기 위해 법률적인 쟁점에 관여하고 특허 침해에 대응하며, 관련 소송에 대처하는 업무 수행',
        },
        {
          name_ko: '시리 언어 엔지니어',
          name_en: 'Siri Language Engineer',
        },
        {
          name_ko: '자동차 시스템 엔지니어',
          name_en: 'Car Experience Systems Engineer',
        },
        {
          name_ko: '햅틱 상호작 디자이너',
          name_en: 'Haptic Interaction Designer',
        },
        {
          name_ko: '증강현실 경험 엔지니어',
          name_en: 'Augmented Reality (AR) Experiences Engineer',
        },
        {
          name_ko: '머신러닝 엔지니어',
          name_en: 'Machine Learning Engineer',
        },
      ],
      major_events: [
        {
          year: '2007',
          title: '아이폰 출시',
          desc:
            '애플은 혁신적인 터치스크린 인터페이스와 모바일 인터넷 기능을 갖춘 아이폰을 출시하였고, 이는 스마트폰 시장에 큰 변화를 가져왔으며 주가에 긍정적인 영향을 미쳤습니다. ',
        },
        {
          year: '2010',
          title: '아이패드 출시',
          desc:
            '애플은 태블릿 시장에 아이패드를 출시하며 새로운 영역을 개척했고, 이는 큰 성공을 거두며 주가에 긍정적인 영향을 미쳤습니다.',
        },
        {
          year: '2011',
          title: '스티브 잡스 사망',
          desc:
            '애플의 공동창업자이자 비전을 제시했던 스티브 잡스의 건강 이상설이 퍼지면서 주가에 지속적으로 부정적인 영향을 끼쳐왔고, 사망 후에는 주가가 소폭 하락했습니다. ',
        },
        {
          year: '2014',
          title: '아이폰6 및 아이폰6 플러스 출시',
          desc:
            '애플은 더 큰 화면 크기의 아이폰6와 아이폰6 플러스를 출시하며 소비자 수요를 충족시켰고, 이는 판매량 증가와 함께 주가에 긍정적인 영향을 미쳤습니다.',
        },
        {
          year: '2017',
          title: '배터리게이트 스캔들',
          desc:
            '애플이 오래된 아이폰 모델의 배터리 성능을 의도적으로 저하시킨 사실이 알려지면서 애플의 브랜드 이미지와 주가에 부정적인 영향을 미쳤습니다. ',
        },
        {
          year: '2018',
          title: '서비스 사업 확대',
          desc:
            '애플은 하드웨어 매출 성장 둔화를 보완하기 위해 애플 뮤직, 애플 TV+, 애플 아케이드 등 다양한 서비스를 확대했습니다. 이는 주가에 긍정적인 영향을 미쳤습니다.',
        },
        {
          year: '2018',
          title: '미중 무역전쟁',
          desc:
            '미국과 중국 간의 무역 분쟁이 심화되면서 애플의 주요 생산기지인 중국의 불확실성이 커졌고, 이는 애플 주가의 변동성을 높였습니다.',
        },
        {
          year: '2020',
          title: '애플 실리콘 전환',
          desc:
            '애플은 인텔 칩에서 자체 설계한 애플 실리콘(M1)으로 전환을 발표하고 이를 성공적으로 구현했습니다. 이는 성능과 배터리 수명에서 큰 개선을 이루어 주가에 긍정적인 영향을 미쳤습니다',
        },
        {
          year: '2021',
          title: '플카 개발 소식',
          desc:
            '애플이 자율주행 전기차 개발에 박차를 가하고 있다는 소식이 전해지면서, 애플의 새로운 성장 동력에 대한 기대감이 높아졌고 주가가 상승했습니다.',
        },
        {
          year: '2024',
          title: '새로운 AI 전략 공개',
          desc:
            '애플이 아이폰과 아이패드 등에 탑재될 인공지능(AI) 전략들을 공개하면서, 제품 성능의 향상에 대한 기대감과 함께 주가에 긍정적인 영향을 미쳤습니다.',
        },
      ],
      sec_link:
        'https://www.sec.gov/edgar/browse/?CIK=0000320193&owner=exclude',
      keyword_tag: [
        {
          name_ko: '스마트폰',
          name_en: '',
          desd: '',
        },
        {
          name_ko: '아이폰',
          name_en: 'iPhone',
          desd: '',
        },
        {
          name_ko: '스티브잡스',
          name_en: 'Steve jobs',
          desd: '',
        },
      ],

      etf_info: {
        asset_management_insitution: 'BlackRock, Inc.',
        inception_date: '1999-03-10',
        one_year_return: 32.47,
        three_year_return: 12.14,
      },
      etf_introduce: [
        {
          title: 'NASDAQ-100 지수를 추종해요',
          desc:
            'QQQ는 나스닥 100 지수를 추종하는 상장지수펀드(ETF)로, 나스닥 시장에 상장된 상위 100개 비금융 대형주에 투자합니다. 주요 투자 대상에는 기술, 통신, 소비재, 헬스케어 등의 섹터에 속한 대형 기업들이 포함됩니다.',
        },
        {
          title: '나스닥 100이 뭘까?',
          desc:
            'NASDAQ-100은 미국의 나스닥 주식시장에서 거래되는 상위 100개 비금융 주식으로 구성된 주가 지수입니다. 이 지수는 주로 기술, 통신, 생명공학, 소프트웨어, 소비자 서비스 등의 분야에서 많은 기업이 포함되어 있어서 투자자들에게 기술 및 비금융 부문의 시장 흐름을 파악하는 중요한 지표로 활용됩니다.',
        },
      ],
      major_investment_firms: [
        {
          connect_card: true,
          code: 'ddd',
          name: '애플 APPLE',
        },
      ],
    };
  }

  init() {
    this.model.setData(this.temp_data);
  }
  addData(type, index) {
    var parentDataName = '';
    var itemDataName = '';
    switch (type) {
      // 매출구성
      case 'sales_composition': {
        parentDataName = 'sales_composition';
        itemDataName = this.model.sales_composition_item;
        break;
      }
      // 매출구성 > 내용
      case 'sales_composition_item_info_list': {
        // parentDataName = 'sales_composition_item[0].info_list';
        // itemDataName = '';
        this.model.sales_composition[index].info_list.push('');
        return;
      }
      // 주목해야 할 포인트
      case 'focus_points': {
        parentDataName = 'focus_points';
        itemDataName = this.model.focus_points_item;
        break;
      }
      // 스페셜 직업
      case 'special_jobs': {
        parentDataName = 'special_jobs';
        itemDataName = this.model.special_jobs_item;
        break;
      }
      // 주요한 사건들
      case 'major_events': {
        parentDataName = 'major_events';
        itemDataName = this.model.major_events_item;
        break;
      }
      // 단어카드
      case 'keyword_tag': {
        parentDataName = 'keyword_tag';
        itemDataName = this.model.keyword_tag_item;
        break;
      }
      
      // ETF 소개
      case 'etf_introduce': {
        parentDataName = 'etf_introduce';
        itemDataName = this.model.etf_introduce_item;
        break;
      }
      // 대표적인 투자 기업
      case 'major_investment_firms': {
        parentDataName = 'major_investment_firms';
        itemDataName = this.model.major_investment_firms_item;
        break;
      }
    }
    this.model[parentDataName].push(itemDataName);
  }
  removeData(type, index, subIndex) {
    var parentDataName = '';
    var itemDataName = '';
    switch (type) {
      // 매출구성
      case 'sales_composition': {
        parentDataName = 'sales_composition';
        itemDataName = this.model.sales_composition_item;
        break;
      }
      // 매출구성 > 내용
      case 'sales_composition_item_info_list': {
        // parentDataName = 'sales_composition_item[0].info_list';
        // itemDataName = '';
        if (this.model.sales_composition[index].info_list.length === 1) {
          this.model.sales_composition[index].info_list.splice(subIndex, 1, '');
        } else {
          this.model.sales_composition[index].info_list.splice(subIndex, 1);
        }
        return;
      }
      // 주목해야 할 포인트
      case 'focus_points': {
        parentDataName = 'focus_points';
        itemDataName = this.model.focus_points_item;
        break;
      }
      // 스페셜 직업
      case 'special_jobs': {
        parentDataName = 'special_jobs';
        itemDataName = this.model.special_jobs_item;
        break;
      }
      // 주요한 사건들
      case 'major_events': {
        parentDataName = 'major_events';
        itemDataName = this.model.major_events_item;
        break;
      }
      // 단어카드
      case 'keyword_tag': {
        parentDataName = 'keyword_tag';
        itemDataName = this.model.keyword_tag_item;
        break;
      }

      // ETF 소개
      case 'etf_introduce': {
        parentDataName = 'etf_introduce';
        itemDataName = this.model.etf_introduce_item;
        break;
      }
      // 대표적인 투자 기업
      case 'major_investment_firms': {
        parentDataName = 'major_investment_firms';
        itemDataName = this.model.major_investment_firms_item;
        break;
      }
    }
    if (this.model[parentDataName].length === 1) {
      this.model[parentDataName].splice(index, 1, itemDataName);
    } else {
      this.model[parentDataName].splice(index, 1);
    }
  }
}
