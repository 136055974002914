<!-- eslint-disable prettier/prettier -->
<template>
  <PageWithLayout>
    <!-- <div class="area_overflow">
      <div class="area_right">
        <div class="box_preview">
          <div class="head_perview">
            <button class="btn_close">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_3310_18379)">
                  <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#232323"/>
                </g>
                <defs>
                  <clipPath id="clip0_3310_18379">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <div class="card_stock">
            <div class="body_card">
              <span class="txt_industry">{{ this.viewModel.model.industry }}</span>
              <div class="area_img">
                <div class="img_stock_logo">종목로고</div>
                <div class="img_country">국기</div>
              </div>
              <strong class="txt_code">{{ this.viewModel.model.code }}</strong>
              <p class="desc_my_stock">내 주식<span class="txt_shares">0.00001주</span></p>
            </div>
            <div class="foot_card">
              <div class="area_left img_level">새싹이미지</div>
              <div class="area_overflow">
                <p class="desc_hold_amount">6,161원 보유</p>
                <p class="desc_value_shift">+1,204원<span class="txt_percent">1.2%</span></p>
              </div>
            </div>
          </div>
          <PreviewDefault
            v-if="viewModel.model.industry != 'ETF'"
            :model.sync="viewModel.model" />
          <PreviewEtf
            v-else
            :model.sync="viewModel.model" />
        </div>
      </div> -->
      <div class="cont_form area_overflow">
        <!-- 기본정보 -->
        <BasicInfo :model.sync="viewModel.model"/>
        <!-- 기본 폼 -->
        <StockDetailForm
          v-if="viewModel.model.industry != 'ETF'"
          :model.sync="viewModel.model"
          @addData="(type) => viewModel.addData(type)"
          @removeData="(type,index) => viewModel.removeData(type,index)"/>
        <!-- ETF 폼 -->
        <StockDetailEtfForm
          v-else
          :model.sync="viewModel.model"
          @addData="(type) => viewModel.addData(type)"
          @removeData="(type,index) => viewModel.removeData(type,index)"/>
      </div>
    <!-- </div> -->
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BasicInfo from '@/views/stock/detail/view/components/BasicInfo';
import StockDetailForm from '@/views/stock/detail/view/components/StockDetailForm';
import StockDetailEtfForm from '@/views/stock/detail/view/components/StockDetailEtfForm';
import PreviewDefault from '@/views/stock/detail/view/components/PreviewDefault';
import PreviewEtf from '@/views/stock/detail/view/components/PreviewEtf';
// viewModel
import StockDetailViewModel from '@/views/stock/detail/viewModel/StockDetailViewModel';

export default {
  name: 'StockDetail',
  components: {
    PageWithLayout,

    BasicInfo,
    StockDetailForm,
    StockDetailEtfForm,

    PreviewDefault,
    PreviewEtf,
  },
  data() {
    return {
      viewModel: new StockDetailViewModel(),
    };
  },
  beforeMount() {
    this.viewModel.init();
  },
};
</script>

<style>
.cont_lemontree.cont_stock_detail #mArticle{padding-bottom:0}
</style>

<style scoped>
/* .cont_form{height:calc(100vh - 220px);overflow-y:auto} */
.cont_form >>> .tbl_view + div,
.cont_form >>> .tbl_view + .tbl_list,
.cont_form >>> .tbl_list + .tbl_list,
.cont_form >>> .tbl_list + .tbl_view {
  margin-top: 26px;
}
.cont_form >>> .tbl_list .tbl_body td {
  vertical-align: top;
}
.cont_form >>> .tbl_list .tbl_body td .txt_tbl {
  line-height: 38px;
}
.cont_form >>> .tbl_list .tbl_body td .tf_comm + .tf_comm,
.cont_form >>> .tbl_list .tbl_body td .tf_comm + .textarea_comm,
.cont_form >>> .tbl_list .tbl_body td .group_form + .textarea_comm {
  margin-top: 6px;
}
.cont_form >>> .tbl_list .tbl_body td .group_form .tf_comm + .tf_comm {
  margin: 4px;
}

/* .box_preview{width:360px;height:768px;overflow-y:auto;border-radius:20px;border:10px solid #999;background:#fff linear-gradient(to bottom, #01CD80, #fff 355px);background-attachment:local}
.head_perview{text-align:right}
.head_perview .btn_close{display:inline-block;padding:15px 20px}

.card_stock{overflow:hidden;margin:24px 40px 36px;border-radius:32px;background:#A1FFDA;box-shadow:0px 0px 24px 12px rgba(0, 0, 0, 0.12);text-align:center}
.body_card{padding:24px 0}
.txt_industry{display:block;font-size:20px;line-height:28px;font-weight:700;color:#111}
.area_img{position:relative;width:100px;height:100px;margin:24px auto 0}
.area_img .img_stock_logo{display:block;width:100px;height:100px;border-radius:100%;border:1px solid #232323;box-sizing:border-box}
.area_img .img_country{position:absolute;right:0;bottom:0;display:block;width:32px;height:32px;margin:0 auto;border-radius:100%;border:1px solid #fff;box-sizing:border-box}
.txt_code{display:block;margin-top:24px;font-size:28px;font-weight:700;line-height:36px;color:#111}
.desc_my_stock{margin-top:4px;font-size:14px;line-height:24px;font-weight:600;color:#111}
.desc_my_stock .txt_shares{display:inline-block;padding:4px 8px;margin-left:4px;font-size:12px;line-height:16px;color:#fff;border-radius:100px;background-color:#232323;vertical-align:top}

.foot_card{overflow:hidden;padding:16px 20px;background-color:#fff;text-align:left}
.img_level{width:48px;height:48px;margin-right:12px;border-radius:10px;border:1px solid #232323;background-color:#ccc;box-sizing:border-box}
.desc_hold_amount{font-size:20px;line-height:28px;font-weight:700;color:#111}
.desc_value_shift{margin-top:4px;font-size:14px;line-height:25px;font-weight:600;color:#E23C3C}
.desc_value_shift .txt_percent{display:inline-block;padding:4px 8px;margin-left:4px;font-size:12px;line-height:16px;color:#E23C3C;border-radius:100px;background-color:#FDEFEF;vertical-align:top}


.cont_stock_detail{padding:0 20px}
.box_preview >>> .tit_section{font-size:24px;font-weight:700;line-height:34px;color:#111}
.box_preview >>> .desc_stock{margin-top:8px;font-size:17px;font-weight:400;line-height:28px;color:#333}
.box_preview >>> .desc_market_capitalization{display:inline-block;margin-top:8px;padding:6px 8px;border-radius:100px;font-size:12px;font-weight:500;line-height:16px;color:#111;background-color:#F3F1F1;vertical-align:top} */
</style>
