<template>
  <div>
    <!-- ETF 정보 -->
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:126px" />
        <col />
        <col style="width:126px" />
        <col />
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">ETF 정보</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>
            운용기관<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td>
            <Input :value.sync="model.etf_info.asset_management_insitution" />
          </td>
          <th>
            개시일<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
          </th>
          <td>
            <DatePicker :value.sync="model.etf_info.inception_date" />
          </td>
        </tr>
        <tr>
          <th>
            1년간 수익률<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td>
            <div class="group_form">
              <Input :value.sync="model.etf_info.one_year_return" />
              <span class="txt_tbl">%</span>
            </div>
          </td>
          <th>
            3년간 수익률<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td>
            <div class="group_form">
              <Input :value.sync="model.etf_info.three_year_return" />
              <span class="txt_tbl">%</span>
            </div>
          </td>
        </tr>
      </template>
    </TableView>
    <!-- ETF 소개 -->
    <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">ETF 소개</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="추가하기"
            @onClickBtn="$emit('addData', 'etf_introduce')"
          />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:60px" />
        <col />
        <col style="width:60px" />
      </template>
      <template v-slot:tbl_head>
        <th>No.</th>
        <th>타이틀 / 내용</th>
        <th></th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr v-for="(item, index) in model.etf_introduce" :key="`tr_${index}`">
            <td>
              <span class="txt_tbl">{{ index + 1 }}</span>
            </td>
            <td>
              <Input placeholder="타이틀" :value.sync="item.title" />
              <Textarea
                placeholder="내용"
                :value.sync="item.desc"
                :showLine="3"
              />
            </td>
            <td>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text=""
                @onClickBtn="$emit('removeData', 'etf_introduce')"
              >
                <IconSvg
                  iconName="subtract"
                  :size="12"
                  iconColor="#F04848"
                  style="margin:0"
                />
              </Button>
            </td>
          </tr>
        </tbody>
      </template>
    </TableList>
    <!-- 대표적인 투자 기업 -->
    <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">대표적인 투자 기업</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="추가하기"
            @onClickBtn="$emit('addData', 'major_investment_firms')"
          />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:60px" />
        <col style="width:120px" />
        <col style="width:20%" />
        <col />
        <col style="width:60px" />
      </template>
      <template v-slot:tbl_head>
        <th>Rank</th>
        <th>종목카드 연결</th>
        <th>종목코드</th>
        <th>기업명</th>
        <th></th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr
            v-for="(item, index) in model.major_investment_firms"
            :key="`tr_${index}`"
          >
            <td>
              <span class="txt_tbl">{{ index + 1 }}</span>
            </td>
            <td>
              <SwitchCheck :value.sync="item.connect_card" />
            </td>
            <td>
              <Input :value.sync="item.code" />
            </td>
            <td>
              <Input :value.sync="item.name" />
            </td>
            <td>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text=""
                @onClickBtn="
                  $emit('removeData', 'major_investment_firms', index)
                "
              >
                <IconSvg
                  iconName="subtract"
                  :size="12"
                  iconColor="#F04848"
                  style="margin:0"
                />
              </Button>
            </td>
          </tr>
        </tbody>
      </template>
    </TableList>
  </div>
</template>
<script>
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import DatePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DatePicker';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';

export default {
  name: 'StockDetailEtfForm',
  components: {
    TableView,
    TableList,
    IconSvg,
    Input,
    DatePicker,
    Textarea,
    Button,
    SwitchCheck,
  },
  props: {
    model: Object,
  },
};
</script>
