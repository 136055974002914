<template>
  <div class="cont_stock_detail">
    <div class="section_comm">
      <h2 class="tit_section">{{ this.model.name_ko }}</h2>
      <p class="desc_stock">{{ this.model.description_one_line }}</p>
      <p class="desc_market_capitalization">
        시가총액 {{ this.model.market_capitalization }} USD
      </p>
    </div>
    <div class="section_comm">
      <div class="box_info">
        <strong class="tit_info">운용기관</strong>
        <p class="desc_info">{{ this.model.etf_info.asset_management_insitution }}</p>
      </div>
      <div class="box_info">
        <strong class="tit_info">개시일</strong>
        <p class="desc_info">{{ this.model.etf_info.inception_date }}</p>
      </div>
      <div class="box_info">
        <strong class="tit_info">1년간 수익률</strong>
        <p class="desc_info">{{ this.model.etf_info.one_year_return }}%</p>
      </div>
      <div class="box_info">
        <strong class="tit_info">3년간 수익률</strong>
        <p class="desc_info">{{ this.model.etf_info.three_year_return }}%</p>
      </div>
    </div>
    <div class="section_comm">
      <div
        class="item_introduce"
        v-for="(item, index) in model.etf_introduce"
        :key="`item_introduce_${index}`">
        <strong class="tit_introduce">{{ item.title }}</strong>
        <p class="desc_introduce">{{ item.desc }}</p>
      </div>
    </div>
    <div class="section_comm">
      <strong>대표적인 투자 기업</strong>
      <div
        class="item_major_investment_firms"
        v-for="(item, index) in model.major_investment_firms"
        :key="`item_introduce_${index}`">
        <p class="desc_name">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PreviewEtf',
  props:{
    model: Object
  }
};
</script>
<style scoped>
.tit_stock_name{font-size:24px;font-weight:700;line-height:34px;color:#111}
.desc_stock{margin-top:8px;font-size:17px;font-weight:400;line-height:28px;color:#333}
.desc_market_capitalization{display:inline-block;margin-top:8px;padding:6px 8px;border-radius:100px;font-size:12px;font-weight:500;line-height:16px;color:#111;background-color:#F3F1F1;vertical-align:top}
</style>