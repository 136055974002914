<template>
  <div class="cont_stock_detail">
    <div class="section_comm">
      <h2 class="tit_section">{{ this.model.name_ko }}</h2>
      <p class="desc_stock">{{ this.model.description_one_line }}</p>
      <p class="desc_stock">
        시가총액 {{ this.model.market_capitalization }} USD
      </p>
    </div>
    <div class="section_comm">
      <h2 class="tit_section">매출구성</h2>
      <ul class="list_sales_composition">
        <li
          v-for="(item, index) in model.sales_composition"
          :key="`item_sales_composition_${index}`"
        >
          <span class="txt_percent">{{ item.percent }}%</span>
          <strong class="tit_sales_composition">{{ item.title }}</strong>
          <ul class="list_info">
            <li
              v-for="(info_list_item, info_list_item_index) in item.info_list"
              :key="`item_info_${info_list_item_index}`"
            >
              {{ info_list_item }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="section_comm">
      <h2 class="tit_section">경쟁사 TOP3</h2>
      <ul class="list_competitors_top3">
        <li
          v-for="(item, index) in model.competitors_top3"
          :key="`item_competitors_top3_${index}`"
        >
          <span class="txt_rank">{{ index + 1 }}</span>
          <strong class="tit_competitors_top3">{{ item.name_ko }}</strong>
        </li>
      </ul>
    </div>
    <div class="section_comm">
      <h2>긍정적 전망</h2>
      <strong>{{ this.model.positive_prospect.title }}</strong>
      <p>{{ this.model.positive_prospect.desc }}</p>
    </div>
    <div class="section_comm">
      <h2>부정적 전망</h2>
      <strong>{{ this.model.negative_prospect.title }}</strong>
      <p>{{ this.model.negative_prospect.desc }}</p>
    </div>
    <div class="section_comm">
      <h2>주목해야 할 포인트</h2>
      <div
        v-for="(item, index) in model.focus_points"
        :key="`item_focus_points_${index}`"
      >
        <strong>{{ item.title }}</strong>
        <p>{{ item.desc }}</p>
      </div>
    </div>
    <div class="section_comm">
      <h2>스페셜 직업</h2>
      <div
        v-for="(item, index) in model.special_jobs"
        :key="`item_special_jobs_${index}`"
      >
        <strong>{{ item.name_ko }}</strong>
        <p>{{ item.name_en }}</p>
      </div>
    </div>
    <div class="section_comm">
      <h2>주요한 사건들</h2>
      <div
        v-for="(item, index) in model.major_events"
        :key="`item_major_events_${index}`"
      >
        <span>{{ item.year || '----' }}</span>
        <strong>{{ item.title }}</strong>
        <p>{{ item.desc }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PreviewDefault',
  props: {
    model: Object,
  },
};
</script>
