<template>
  <TableView>
    <template v-slot:tbl_colgroup>
      <col style="width:118px" />
      <col />
      <col style="width:118px" />
      <col />
    </template>
    <template v-slot:tbl_top>
      <div class="area_left">
        <strong class="tit_tbl">기본정보</strong>
      </div>
    </template>
    <template v-slot:tbl_body>
      <tr>
        <th>
          종목명(Ko)<IconSvg
            iconName="essential"
            :size="6"
            iconColor="#F04848"
          />
        </th>
        <td>
          <Input :value.sync="model.name_ko" />
        </td>
        <th>
          종목명(En)<IconSvg
            iconName="essential"
            :size="6"
            iconColor="#F04848"
          />
        </th>
        <td>
          <Input :value.sync="model.name_en" />
        </td>
      </tr>
      <tr>
        <th>
          종목코드<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td>
          <Input :value.sync="model.code" />
        </td>
        <th>
          산업<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td>
          <Selectbox
            :dataList="'stock_industry' | getSelectDataList"
            :value.sync="model.industry"
          />
        </td>
      </tr>
      <tr>
        <th>
          시가총액<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td colspan="3">
          <Input :value.sync="model.market_capitalization" />
        </td>
      </tr>
      <tr>
        <th>
          한줄소개<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td colspan="3">
          <Textarea :value.sync="model.description_one_line" :showLine="3" />
        </td>
      </tr>
      <tr>
        <th>sec 링크 URL</th>
        <td colspan="3">
          <Input :value.sync="model.sec_link" />
        </td>
      </tr>
    </template>
  </TableView>
</template>
<script>
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';

export default {
  name: 'BasicInfo',
  components: {
    TableView,
    IconSvg,
    Input,
    Selectbox,
    Textarea
  },
  props: {
    model: Object,
  },
};
</script>
